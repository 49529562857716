import React, { useEffect, useState, useRef, Fragment } from "react";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import axios from 'axios'
import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { protectedResources, msalConfig } from '../authConfig';
import { getClaimsFromStorage } from '../utils/storageUtils';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";
import Zoom from '@mui/material/Zoom';


moment.locale("en-CA", {
  week: {
    dow: 1,
    doy: 1,
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Array.prototype.pushIfNotExist = function (obj) {
  const foundItem = this.find((item) => item.team === obj.team);
  if (!foundItem) {
    this.push(obj);
  }
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const { forEach } = require('p-iteration');

moment.locale("en-CA", {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  export const Reports = () => {

    const theme = useTheme();

    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [graphData, setGraphData] = useState(null);
    const resource = new URL(protectedResources.graphContacts.endpoint).hostname;
    
    const claims =
        account && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            ? window.atob(
                getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            )
            : undefined; // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
    
    const request = {
        scopes: protectedResources.graphContacts.scopes,
        account: account,
        claims: claims
    };

    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });


    useEffect(() => {
        //on login
        if (!!graphData) {
            return;
        }

        if (!!error) {
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }
    }, [graphData, result, error, login]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    async function acquireDataverseToken(){
      return await instance.acquireTokenSilent({
          scopes: ['https://coder2.crm3.dynamics.com/.default'],
          account: account
            })
    }
    async function acquireGraphToken(){
      return await
        instance.acquireTokenSilent({
            scopes: ["user.read","Group.Read.All","GroupMember.Read.All","Team.ReadBasic.All","TeamMember.Read.All"],
            account: account
        })
    
    }

    var today = new Date()
    const [ startingInitDate, setStartingInitDate ] = useState(new Date(today.getFullYear(),today.getMonth()-1,1));
    const [ initDateWithOffset, setInitDateWithOffset ] = useState(new Date(today.getFullYear(),today.getMonth()-1,0));

    const [ endingInitDate, setEndingInitDate ] = useState(new Date());
    const [student, setStudent] = useState({});
    const [noteids, setNoteids] = useState([]);
    const [notes,setNotes] = useState("");
    const [newNotes,setNewNotes] = useState("");

    const [teams, setTeams] = useState(null);
    const [studentsData, setStudentsData] = useState([{label:" "}])
    const [studentAutocompleteValue, setStudentAutocompleteValue] = useState({label:""})
    const [teamsData, setTeamsData] = useState([{}])
    const [toggleStudent, setToggleStudent]= useState(false)
    const [startDateToggled, setStartDateToggled]= useState(false)
    const [endDateToggled, setEndDateToggled]= useState(false)
    const [studentSelected, setStudentSelected]=useState(false)
    const [studentsLoading, setStudentsLoading]=useState(true)
    const [notesLoading, setNotesLoading]=useState(true)
    const [value, setValue] = React.useState(0);
    const [mappedNotesArr, setMappedNotesArr]=useState([])
    const [mobile, setMobile] = useState(false);
  const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  }
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobile(true)
        : setMobile(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  useDidMountEffect(() => {
    async function getNotesIds() {
      if (studentSelected) {
        let dataverseToken = await acquireDataverseToken();
        let data = {
          accessToken: dataverseToken.accessToken,
          roles: dataverseToken.idTokenClaims.roles,
          contactid: student.contactid,
          start: initDateWithOffset,
          end:endingInitDate
        }
        let noteids = []
        let res = await axios.get("/api/noteids/" + JSON.stringify(data))
        res.data.status.value.forEach(note => {
          noteids.push(note.cr855_note_guid)
        })
        setNoteids(noteids)
      }

    }
    getNotesIds()
  }, [toggleStudent,startingInitDate,endingInitDate])

  useDidMountEffect(() => {
    async function getNotesData() {
        let notesarr = []
        let teamsmap = new Map()
        let dataverseToken = await acquireDataverseToken();
        await forEach(noteids, async(id)=>{
          let data = {
            accessToken: dataverseToken.accessToken,
            roles: dataverseToken.idTokenClaims.roles,
            noteid: id,
            start: initDateWithOffset,
            end:endingInitDate
          }
          let res = await axios.get("/api/notefromid/" + JSON.stringify(data))
          

          res.data.status.value.forEach(note => {

            let team = teamsData.find((team)=>team.id === note.cr855_group)
            note.team = team!=null?team.displayName:"Individual"
            
            
            notesarr.push(note)
        })
        

        })
        notesarr.forEach(note=>{teamsmap.set(note.team, teamsmap.get(note.team)+1 || 1)} )
        let mappednotes = notesarr.map(note=> {return {date: moment(note.cr855_date), note:note.cr855_note}})
        mappednotes.sort((a,b) => a.date.diff(b.date))
        mappednotes = mappednotes.map(note=>{return {date: note.date.toISOString().slice(0,10), note:note.note}})
        mappednotes=  mappednotes.reverse()
      
        setMappedNotesArr( Object.values(mappednotes))
        setTeams(teamsmap)  
      
    }
    getNotesData()
  }, [noteids])
 
  useDidMountEffect(() => {
    let notes = mappedNotesArr.map(note=>{
        return(<TextField
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            multiline
            value={note.date + '\n' + note.note.replace(/<br ?\/?>/g, "\n")}
            fullWidth={true}
            style={{marginTop:"10px",marginBottom:"10px"}}
        />)
    })
    setNotes(notes)
    setNotesLoading(false)

  }, [mappedNotesArr])

  useEffect(() => {
    async function token(){
        
      let students = []
      let teams = []
      let dataverseToken = await acquireDataverseToken();
      let graphToken = await acquireGraphToken();

      let data = {
        accessToken: dataverseToken.accessToken,
        roles: dataverseToken.idTokenClaims.roles,
      }
      let graphdata = {
        accessToken: graphToken.accessToken,
        roles: graphToken.idTokenClaims.roles,
      }
      axios.get("/api/students/" + JSON.stringify(data)).then(response => {
        response.data.status.value.forEach((studentData) => {
            studentData.label = studentData.fullname
            students.push(studentData)
        })

        students.sort((a,b)=>a.fullname.localeCompare(b.fullname))
        setStudentsData(students)
        setStudentsLoading(false)


      axios.get("/api/teams/" + JSON.stringify(graphdata)).then(response => {
        response.data.status.value.forEach(team => {
          teams.push(team)
        })
        setTeamsData(teams)
      })})

    }
    token()
  }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
};

const handleChangeIndex = (index) => {
    setValue(index);
};

  const handleStartingInitDateChange = (date) => {
    let offsetdate =  moment(date).subtract(1,'days')
    setStartingInitDate(date)

    setInitDateWithOffset(offsetdate)
    setStartDateToggled(!startDateToggled)
  }

  const handleEndingInitDateChange = (date) => {
    setEndingInitDate(date)
    setEndDateToggled(!endDateToggled)

  }

  async function handleSubmission(){
    let today = new Date()
    today.setHours(0,0,0,0)
    let formattedNotes = newNotes.replace(/\n/g, "<br>");
    let graphToken = await acquireDataverseToken();
    let dataverseToken = await acquireDataverseToken();
    let adjustedToday = today.toDateString().slice(0,16)
    let data = {
      cr855_date:adjustedToday,
      cr855_note: formattedNotes,
      cr855_group: null,
      cr855_coach:account.username,
      accessToken: graphToken.accessToken,
      roles: graphToken.idTokenClaims.roles,
    }
    let res = await axios.post('/api/createNote/'+encodeURIComponent(JSON.stringify(data)))
    let noteId = res.data.status.cr855_studentnoteid
    let contactdata = {
      accessToken: graphToken.accessToken,
      roles: graphToken.idTokenClaims.roles,
      mail:student.cr855_codersportsemail
    }
  let contactres = await axios.get('/api/contact/'+ JSON.stringify(contactdata))
  let contact = contactres.data.status.value[0].contactid
  
    let linkeddata = {
        accessToken: dataverseToken.accessToken,
        roles: dataverseToken.idTokenClaims.roles,
        cr855_note_guid:noteId,
        cr855_contact_guid: contact
    }
    await axios.post('/api/linkContactToNote/'+JSON.stringify(linkeddata))
    window.location.reload()  
  }

  const handleStudentChange = (v)=>{
    setNotesLoading(true)
    setToggleStudent(!toggleStudent)
    setStudentAutocompleteValue(v);
    if(v!=null){
      setStudent(v);
      setStudentSelected(true)
    }
    else{
      setStudent({});
      setStudentSelected(false);}
    
  }

  const defaultProps = {
    options: studentsData,
    getOptionLabel: option => option.label
  }
  if(mobile){
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',         
          }}>
            <div style={{textAlign: "center"}}>
            <Box  sx={{ bgcolor: 'background.paper',  boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                <Typography componet="h2" variant="h5" gutterBottom>
                    Student
                </Typography>
              {studentsLoading?<CircularProgress/>:<div style={{display: 'flex', alignItems: 'center',
            justifyContent: 'center'}}>
                <Autocomplete
                {...defaultProps}
                id="combo-box-demo"
                renderOption= {(props,option) => {
                  return(
                  <div>
                    <Tooltip placement="left" title={option.cr855_codersportsemail}>
                    <Button sx={{ textTransform: 'none' }}  component="li" {...props} fullWidth>{props.key}</Button> 
                    </Tooltip>
                    </div> 
                  )
                 }
                }
                
                value={studentAutocompleteValue}
                style={{width:"60%"}}
                onChange={(e, v) => {
                  handleStudentChange(v)
                  }}
                renderInput={(params) => <TextField margin="dense" {...params} label="Student" />}
                />
            </div>
            }
            </Box>

            <Zoom in = {studentSelected}>
            <Box sx={{ bgcolor: 'background.paper', padding:1,boxShadow: 4 , marginBottom:5 }}>
            <AppBar style={{ background: '#F7941E' }} position="static">
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="default"
                variant="fullWidth"
                aria-label="full width tabs example"
                >
                <Tab label="Report" {...a11yProps(0)} />
                <Tab label="Create Individual Student Note" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <SwipeableViews 
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                
                <Typography componet="h2" variant="h5" style={{paddingBottom:"25px"}} >
                    Attendance Report for {student.fullname}
                </Typography>
                <div style={{marginBottom:"25px"}}>
            <img src={`data:image/png;base64, ${student.entityimage}`} class="rounded" />
            
            </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>

                <DesktopDatePicker
                  
                  label="Starting Date"
                  inputFormat="MM/DD/yyyy"
                  value={startingInitDate}
                  onChange={handleStartingInitDateChange}
                  renderInput={(params) => <TextField {...params} sx={{mb:2}}/>}
                />
                <DesktopDatePicker
                  label="Ending Date"
                  inputFormat="MM/DD/yyyy"
                  value={endingInitDate}
                  onChange={handleEndingInitDateChange}

                  renderInput={(params) => <TextField {...params} />}
                />
                </LocalizationProvider>

                <div style={{display:'flex',justifyContent:'center'}}>
                <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
                <TableContainer component={Paper}>
                <Table  aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Team</StyledTableCell>
                      <StyledTableCell align="left">Classes attended</StyledTableCell>                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams!=null?Array.from(teams.keys()).map((team)=>(
                      
                        <StyledTableRow>
                        <StyledTableCell align="left">{team}</StyledTableCell>
                        <StyledTableCell align="left">{teams.get(team)}</StyledTableCell>
                        </StyledTableRow>
                      
                    )):null}
                    
                  </TableBody>
                </Table>
              </TableContainer>
              </Box>              
              </div>
              <Typography componet="h2" variant="h5">
                    Notes
                </Typography>
                <div style={{display:'flex',justifyContent:'center'}}>
                <Box  sx={{ bgcolor: 'background.paper',  boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
                {notes}                 
                </Box>
                </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>              
                <TextField
                        label="Notes"
                        variant="outlined"
                        multiline
                        rows={18}
                        value={newNotes}
                        fullWidth={true}
                        onChange={(e)=>setNewNotes(e.target.value)}
                        style={{marginTop:"3px"}}
                    />
                <Button  sx={{ marginBottom: 5 }} disabled={newNotes===''?true:false} style={{backgroundColor:"white", width:'50%'}}onClick={()=>{handleSubmission();}} color='primary' variant="outlined" >Submit</Button>
                </TabPanel>            
            </SwipeableViews>           
                 </Box>
                 </Zoom>           
            </div>
        </div>
   )   }

   else{
    return (

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',         
      }}>
        <div style={{textAlign: "center"}}>
        <Box  sx={{ bgcolor: 'background.paper', width: 720, boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
            <Typography componet="h2" variant="h5" gutterBottom>
                Student
            </Typography>
          {studentsLoading?<CircularProgress/>:<div style={{width:720,display: 'flex', alignItems: 'center',
        justifyContent: 'center'}}>
            <Autocomplete
            {...defaultProps}
            id="combo-box-demo"
            renderOption= {(props,option) => {
              return(
              <div>
                <Tooltip placement="left" title={option.cr855_codersportsemail}>
                <Button sx={{ textTransform: 'none' }}  component="li" {...props} fullWidth>{props.key}</Button> 
                </Tooltip>
                </div> 
              )
             }
            }
            
            value={studentAutocompleteValue}
            style={{width:"60%"}}
            onChange={(e, v) => {
              handleStudentChange(v)
              }}
            renderInput={(params) => <TextField margin="dense" {...params} label="Student" />}
            />
        </div>
        }
        </Box>
        {notesLoading?<CircularProgress/> :<div>
        <Zoom in = {studentSelected}>
        <Box sx={{ bgcolor: 'background.paper', width: 720, padding:1,boxShadow: 4 , marginBottom:5 }}>
        <AppBar style={{ background: '#F7941E' }} position="static">
            <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="default"
            variant="fullWidth"
            aria-label="full width tabs example"
            >
            <Tab label="Report" {...a11yProps(0)} />
            <Tab label="Create Individual Student Note" {...a11yProps(1)} />
            </Tabs>
        </AppBar>
        <SwipeableViews 
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
        >
            <TabPanel value={value} index={0} dir={theme.direction}>
            
            <Typography componet="h2" variant="h5" style={{paddingBottom:"25px"}} >
                Attendance Report for {student.fullname}
            </Typography>
            <div style={{marginBottom:"25px"}}>
            <img src={`data:image/png;base64, ${student.entityimage}`} class="rounded" />
            
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>

            <DesktopDatePicker
              
              label="Starting Date"
              inputFormat="MM/DD/yyyy"
              value={startingInitDate}
              onChange={handleStartingInitDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label="Ending Date"
              inputFormat="MM/DD/yyyy"
              value={endingInitDate}
              onChange={handleEndingInitDateChange}

              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>

            {notesLoading?<CircularProgress/>:<div style={{display:'flex',justifyContent:'center'}}>
            <Box  sx={{ bgcolor: 'background.paper', width: 420, boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 420 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Team</StyledTableCell>
                  <StyledTableCell align="left">Classes attended</StyledTableCell>                     
                </TableRow>
              </TableHead>
              <TableBody>
                {teams!=null?Array.from(teams.keys()).map((team)=>(
                  
                    <StyledTableRow>
                    <StyledTableCell align="left">{team}</StyledTableCell>
                    <StyledTableCell align="left">{teams.get(team)}</StyledTableCell>
                    </StyledTableRow>
                  
                )):null}
                
              </TableBody>
            </Table>
          </TableContainer>
          </Box>              
          </div>}
          <Typography componet="h2" variant="h5">
                Notes
            </Typography>
            <div style={{display:'flex',justifyContent:'center'}}>
            <Box  sx={{ bgcolor: 'background.paper', width: 620, boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
            {notes}                 
            </Box>
            </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>              
            <TextField
                    label="Notes"
                    variant="outlined"
                    multiline
                    rows={18}
                    value={newNotes}
                    fullWidth={true}
                    onChange={(e)=>setNewNotes(e.target.value)}
                    style={{marginTop:"3px"}}
                />
            <Button  sx={{ marginBottom: 5 }} disabled={newNotes===''?true:false} style={{backgroundColor:"white", width:'50%'}}onClick={()=>{handleSubmission();}} color='primary' variant="outlined" >Submit</Button>
            </TabPanel> 
        </SwipeableViews>           
             </Box>
             </Zoom>           
        </div>
       
                 } </div></div>)}}
