import React, { useEffect, useState, useRef, Fragment } from "react";
import moment from "moment";
import axios from 'axios'
import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { protectedResources, msalConfig } from '../authConfig';
import { getClaimsFromStorage } from '../utils/storageUtils';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import {Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";
import Zoom from '@mui/material/Zoom';

  export const PasswordManager = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [graphData, setGraphData] = useState(null);
    const resource = new URL(protectedResources.graphContacts.endpoint).hostname;

    const claims =
        account && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            ? window.atob(
                getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            )
            : undefined; // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
    
    const request = {
        scopes: protectedResources.graphContacts.scopes,
        account: account,
        claims: claims
    };
    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });

    useEffect(() => {
        //on login
        if (!!graphData) {
            return;
        }

        if (!!error) {
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }
    }, [graphData, result, error, login]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    async function acquireDataverseToken(){
      return await instance.acquireTokenSilent({
          scopes: ['https://coder2.crm3.dynamics.com/.default'],
          account: account
            })
    }
    async function acquireGraphToken(){
      return await
        instance.acquireTokenSilent({
            scopes: ["user.read","Group.Read.All","GroupMember.Read.All","Team.ReadBasic.All","TeamMember.Read.All"],
            account: account
        })
    
    }
    const [student, setStudent] = useState({});
    const [password, setPassword] = useState("");
    const [studentsData, setStudentsData] = useState([{label:" "}])
    const [studentAutocompleteValue, setStudentAutocompleteValue] = useState({label:""})
    const [toggleStudent, setToggleStudent]= useState(false)
    const [dialogOpen, setDialogOpen]=useState(false)
    const [studentSelected, setStudentSelected]=useState(false)
    const [studentsLoading, setStudentsLoading]=useState(true)
    const [mobile, setMobile] = useState(false);

  useEffect(() => {
    async function token(){
        
      let students = []
      let dataverseToken = await acquireDataverseToken();
      let graphToken = await acquireGraphToken();

      let data = {
        accessToken: dataverseToken.accessToken,
        roles: dataverseToken.idTokenClaims.roles,
      }
      axios.get("/api/students/" + JSON.stringify(data)).then(response => {
        response.data.status.value.forEach((studentData) => {
            studentData.label = studentData.fullname
            students.push(studentData)
        })

        students.sort((a,b)=>a.fullname.localeCompare(b.fullname))
        setStudentsData(students)
        setStudentsLoading(false)
    })
    }
    token()
  }, [])

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobile(true)
        : setMobile(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);


  async function handleSubmission(){            
    let graphToken = await acquireGraphToken();
    
    let data = {
      accessToken: graphToken.accessToken,
      roles: graphToken.idTokenClaims.roles,
      student:student.cr855_codersportsemail,
      body:{newPassword:password}
    }
    let res = await axios.get("/api/passwordMethods/" + JSON.stringify(data))
    console.log(res)
    let passId = res.data.status.value[0].id

    data.passwordId = passId
    let postres = await axios.post("/api/updatePassword/" + JSON.stringify(data))
    if(postres.status == 200){
      handleDialogOpen()
    }
  }
  
  const handleDialogOpen = () => {
    setDialogOpen(true);
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  const handleStudentChange = (v)=>{
    setToggleStudent(!toggleStudent)
    setStudentAutocompleteValue(v);
    if(v!=null){
      setStudent(v);
      console.log(v)
      setStudentSelected(true)
      setPassword("Coder" + v.lastname.replace(/[^a-z]/gi, '') +"1")

    }
    else{
      setStudent({});
      setPassword("")
      setStudentSelected(false);}
    
  }

  const defaultProps = {
    options: studentsData,
    getOptionLabel: option => option.label
  }
  if(mobile){
    return (
        <div style={{
            alignItems: 'center',
            justifyContent: 'center',         
          }}>
             <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DialogTitle>Successfully reset password</DialogTitle>
              <DialogActions>
                <Button variant='outlined' onClick={handleDialogClose}>Continue</Button>
              </DialogActions>

            </Dialog>
            <div style={{textAlign: "center"}}>
            <Box  sx={{ bgcolor: 'background.paper',  boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                <Typography componet="h2" variant="h5" gutterBottom>
                    Student
                </Typography>
              {studentsLoading?<CircularProgress/>:<div style={{display: 'flex', alignItems: 'center',
            justifyContent: 'center'}}>
                <Autocomplete
                {...defaultProps}
                id="combo-box-demo"
                renderOption= {(props,option) => {
                  return(
                  <div>
                    <Tooltip placement="left" title={option.cr855_codersportsemail}>
                    <Button sx={{ textTransform: 'none' }}  component="li" {...props} fullWidth>{props.key}</Button> 
                    </Tooltip>
                    </div> 
                  )
                 }
                }               
                value={studentAutocompleteValue}
                style={{width:"80%"}}
                onChange={(e, v) => {
                  handleStudentChange(v)
                  }}
                renderInput={(params) => <TextField margin="dense" {...params} label="Student" />}
                />
            </div>
            }
            </Box>
            <Zoom in = {studentSelected}>
            <Box  sx={{ bgcolor: 'background.paper',marginLeft:3,marginRight:3, padding:2,boxShadow: 4 , marginBottom:5 }}>
            <Typography componet="h2" variant="h5" gutterBottom>
                    New Password
                    <div> 
                        <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                   
                </Typography>
            </Box>        
            </Zoom>
            <Zoom in = {studentSelected}>
            <Button  disabled={password===''?true:false} onClick={()=>{handleSubmission()}} color='primary' variant="outlined" >Submit</Button> 
            </Zoom>
            </div>
        </div>
   )   }
   else{
   return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',         
          }}>
             <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DialogTitle>Successfully reset password</DialogTitle>
              <DialogActions>
                <Button variant='outlined' onClick={handleDialogClose}>Continue</Button>
              </DialogActions>

            </Dialog>
            <div style={{textAlign: "center"}}>
            <Box  sx={{ bgcolor: 'background.paper', width: 720, boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                <Typography componet="h2" variant="h5" gutterBottom>
                    Student
                </Typography>
              {studentsLoading?<CircularProgress/>:<div style={{width:720,display: 'flex', alignItems: 'center',
            justifyContent: 'center'}}>
                <Autocomplete
                {...defaultProps}
                id="combo-box-demo"
                renderOption= {(props,option) => {
                  return(
                  <div>
                    <Tooltip placement="left" title={option.cr855_codersportsemail}>
                    <Button sx={{ textTransform: 'none' }}  component="li" {...props} fullWidth>{props.key}</Button> 
                    </Tooltip>
                    </div> 
                  )
                 }
                }               
                value={studentAutocompleteValue}
                style={{width:"60%"}}
                onChange={(e, v) => {
                  handleStudentChange(v)
                  }}
                renderInput={(params) => <TextField margin="dense" {...params} label="Student" />}
                />
            </div>
            }
            </Box>
            <Zoom in = {studentSelected}>
            <Box sx={{ bgcolor: 'background.paper', width: 720, padding:1,boxShadow: 4 , marginBottom:5 }}>
            <Typography componet="h2" variant="h5" gutterBottom>
                    New Password
                    <div> 
                        <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                   
                </Typography>
            </Box>        
            </Zoom>
            <Zoom in = {studentSelected}>
            <Button  disabled={password===''?true:false} onClick={()=>{handleSubmission()}} color='primary' variant="outlined" >Submit</Button> 
            </Zoom>
            </div>
        </div>
   )
   }
  }

