import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Box from '@mui/material/Box';
import  Card  from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardActionArea from "@mui/material/CardActionArea";
import InsightsIcon from '@mui/icons-material/Insights';
import CardContent from "@mui/material/CardContent";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PhoneIcon from '@mui/icons-material/Phone';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LockResetIcon from '@mui/icons-material/LockReset';/***

 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
  
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      
      
    const styles = {
    
        cardContainer: {
          textAlign:"center", display: "inline" 
        },
      };
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    if(activeAccount!=null && activeAccount.idTokenClaims.roles!=null && activeAccount.idTokenClaims.roles.includes("Coach")){
        return (
            <>  
                <AuthenticatedTemplate>                     
                        <h5>
                        <center>
                            Welcome {activeAccount.name}
                            <div style={{display:'flex',justifyContent:'center'}}>               
                <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
                <TableContainer  component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        <Typography>
                            Tools
                        </Typography>

                        </StyledTableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody >
                  <Grid container justifyContent="center" alignItems="center">
                            <Grid  item xs={12} sm={8} md={4}>
                        
                        <Card  elevation={10}style = {styles.cardContainer}>
                        <Link to = {'/Notes'}>
                        <CardActionArea >
                            <CardContent>                           
                            <Typography variant="h5" gutterBottom>
                            <EventNoteIcon/>
                            {" "}Notes
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </Link>
                        </Card>                    
                    </Grid>               
                    <Grid item xs={12} sm={8} md={4}>
                        <Card elevation={10}style = {styles.cardContainer}>
                        <Link to = {'/Notes2'}>
                        <CardActionArea>
                            <CardContent>
                            <Typography variant="h5" gutterBottom>
                            <EventNoteIcon/>
                            {" "}Notes 2
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </Link>                   
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Card  elevation={10}style = {styles.cardContainer}>
                        <Link to = {'/Reports'}>
                        <CardActionArea>
                            <CardContent>
                            <Typography variant="h5" gutterBottom>
                            <InsightsIcon/>
                            {" "}Reports
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </Link>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Card  elevation={10}style = {styles.cardContainer}>
                        <Link to = {'/PasswordManager'}>
                        <CardActionArea>
                            <CardContent>
                            <Typography variant="h5" gutterBottom>
                            <LockResetIcon/>
                            {" "}Passwords
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </Link>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Card  elevation={10}style = {styles.cardContainer}>
                        <Link to = {'/ContactInfo'}>
                        <CardActionArea>
                            <CardContent>
                            <Typography variant="h5" gutterBottom>
                            <PhoneIcon/>
                            {" "}Contact Info
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </Link>
                        </Card>
                    </Grid>    
                    <Grid item xs={12} sm={8} md={4}>
                        <Card  elevation={10}style = {styles.cardContainer}>
                        <a href='https://learn.codersports.com/coach-resources' target="_blank">
                        <CardActionArea>
                            <CardContent>
                            <Typography variant="h5" gutterBottom>
                            <LightbulbIcon/>
                            {" "}Coach Resources
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </a>
                        </Card>
                    </Grid>                       
                </Grid>
                  </TableBody>
                </Table>
              </TableContainer>
              </Box>            
              </div>                     
          </center>
           </h5>
                  
        </AuthenticatedTemplate>
            </>
        );
    }
    else{
        return null
    }
    
};
