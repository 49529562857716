import { useEffect, useState } from 'react';
import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { ContactsData } from '../components/DataDisplay';
import { protectedResources, msalConfig } from '../authConfig';
import { getClaimsFromStorage } from '../utils/storageUtils';
import { handleClaimsChallenge } from '../fetch';
import { getGraphClient } from '../graph';
import { ResponseType } from '@microsoft/microsoft-graph-client';
import React, {useRef, Fragment } from "react";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import _, { map } from 'lodash'
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid'
import {Navigate} from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import {Typography } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";
import { LinearProgress } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import Collapse from '@mui/material/Collapse';

import axios from 'axios'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  Array.prototype.pushIfNotExist = function (obj) {
    const foundItem = this.find((item) => item.mail === obj.mail);
    if (!foundItem) {
      this.push(obj);
    }
  };
  
  const { forEach } = require('p-iteration');
  
  moment.locale("en-CA", {
      week: {
        dow: 1,
        doy: 1,
      },
    });
  
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
          )}
        </div>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };
    
    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    }

export const Notes2 = () => {
  var today = new Date()
    const [redirect, setRedirect ] = useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [coach, setCoach] = useState({});
    const [prevNoteDate, setPrevNoteDate] = useState("");
    const [notes,setNotes] = useState("");
    const [prevNotes, setPrevNotes] = useState([])
    
    const [team, setTeam] = useState('');
    const [prevNotesToDisplay, setPrevNotesToDisplay] = useState('');
    const [coachesData, setCoachesData] = useState([{label:" "}])
    const [coachAutocompleteValue, setCoachAutocompleteValue] = useState({})
    const [teamsData, setTeamsData] = useState([{}])
    const [chosenTeam, setChosenTeam] = useState("")
    const [chosenTeamStudentsData, setChosenTeamStudentsData] = useState([{name:"",mail:"",isChecked:false}])
    const [mappedNotesArr, setMappedNotesArr]=useState([])

    const [nextLinkCoach, setNextLinkCoach] = useState("")
    const [nextLinkTeam, setNextLinkTeam] = useState("")

    const [teamtextfieldvalue, setteamtextfieldvalue] = React.useState(null);
    const [toggleTeam, setToggleTeam]= useState(false)
    const [teamDataLoading, setTeamDataLoading]= useState(true)
    const [studentsLoading, setStudentsLoading]= useState(true)

    const [coachesTeams, setCoachesTeams] = useState([])
    const [coachSelected, setCoachSelected]=useState(false)

    const [prevNoteDateSelected, setPrevNoteDateSelected]=useState(false)
    const [coachesLoading, setCoachesLoading]=useState(true)
    const [mobile, setMobile] = useState(false);
    const [attendanceComplete, setAttendanceComplete] = useState(false);


    const [teamSelected, setTeamSelected]=useState(false)
    const [snackbarOpen, setSnackbarOpen]=useState(false)

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [graphData, setGraphData] = useState(null);
    const resource = new URL(protectedResources.graphContacts.endpoint).hostname;
    
    const claims =
        account && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            ? window.atob(
                getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            )
            : undefined; // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
    
    const request = {
        scopes: protectedResources.graphContacts.scopes,
        account: account,
        claims: claims
    };



    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });



    useEffect(() => {
        //on login
        if (!!graphData) {
            return;
        }

        if (!!error) {
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }
    }, [graphData, result, error, login]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    async function acquireDataverseToken(){
      return await instance.acquireTokenSilent({
          scopes: ['https://coder2.crm3.dynamics.com/.default'],
          account: account
            })
    }

    async function acquireGraphToken(){
      return await
        instance.acquireTokenSilent({
            scopes: ["user.read","Group.Read.All","GroupMember.Read.All","Team.ReadBasic.All","TeamMember.Read.All"],
            account: account
        })
    
    }
  

    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    
  const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  }

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobile(true)
        : setMobile(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  useEffect(() => {
    async function fetchtoken(){
      let coaches = []
      let teams = []
      let graphToken = await acquireGraphToken();
      
        let data = {
          accessToken: graphToken.accessToken,
          roles: graphToken.idTokenClaims.roles
        }
 
        axios.get("/api/coaches/" + JSON.stringify(data)).then(response => {
         response.data.status.value.forEach((coachData) => {
           if (coachData.id !== "493aa65b-b378-4803-accf-bbe0ec846c1e")
             coaches.push(coachData)
   
         })
   
         const lookup = coaches.reduce((a, e) => {
           a[e.displayName] = ++a[e.displayName] || 0;
           return a;
         }, {});
         
         let duplicates = coaches.filter(e => lookup[e.displayName])
         duplicates.forEach(duplicate=>{duplicate.displayName = duplicate.mail; duplicate.label = duplicate.displayName;})
   
          coaches.forEach(coach=>{coach.label = coach.displayName; duplicates.pushIfNotExist(coach)})
         coaches.sort((a,b)=>a.displayName.localeCompare(b.displayName))
         setCoachesData(coaches)
         setCoachesLoading(false)
         if (response.data.status['@odata.nextLink'] != null) {
           setNextLinkCoach(response.data.status['@odata.nextLink'])
         }
       })
   
       axios.get("/api/teams/" + JSON.stringify(data)).then(response => {
         response.data.status.value.forEach(team => {
           teams.push(team)
         })
         if (response.data.status['@odata.nextLink'] != null) {
           setNextLinkTeam(response.data.status['@odata.nextLink'])
         }
         else{
           setTeamsData(teams)
           setTeamDataLoading(false)
         }
       })
      
      
    }
    fetchtoken();
  }, [])
  useDidMountEffect(() => {
    async function getTeamsData() {
      
      if (nextLinkTeam!=""&& nextLinkTeam != null) {
        let teamArr = []
        let encodeduri = encodeURIComponent(nextLinkTeam)
        let graphToken = await acquireGraphToken();
        let data = {
          accessToken: graphToken.accessToken,
          roles: graphToken.idTokenClaims.roles,
          encodeduri: encodeduri
        }
        let res = await axios.get("/api/dataNext/" + JSON.stringify(data))
        res.data.status.value.forEach(team => {
          teamArr.push(team)
        })
        setTeamsData([...teamsData, ...teamArr])
        setNextLinkTeam(res.data.status['@odata.nextLink'])
      }
      else{
        setTeamDataLoading(false)
      }
    }
    getTeamsData()
  }, [nextLinkTeam])

  useDidMountEffect(() => {
    if(teamSelected){

      let chosenTeamId = teamsData.find(teamData=>{
        return teamData.displayName === team
      }).id
      setChosenTeam(chosenTeamId)
    }
    setPrevNoteDateSelected(false)
    setPrevNoteDate("")
  }, [toggleTeam])

  useDidMountEffect(() => {

    async function getMembers(){
      let graphToken = await acquireGraphToken();
      
        let data = {
          accessToken: graphToken.accessToken,
          roles: graphToken.idTokenClaims.roles,
          chosenTeam: chosenTeam
        }
    let res = await axios.get('/api/teamMembers/'+JSON.stringify(data))
    let students=[]
    res.data.status.value.forEach(member=>{
      if(!coachesData.some(coach=>{return coach.mail===member.mail})){
        students.push({name:member.displayName,mail:member.mail,isChecked:false})
      }
    })
    setChosenTeamStudentsData(students)
    setStudentsLoading(false)
  }
    

    async function getPreviousNotes(){
      let dataverseToken = await acquireDataverseToken();
      
        let data = {
          accessToken: dataverseToken.accessToken,
          roles: dataverseToken.idTokenClaims.roles,
          chosenTeam: chosenTeam
        }
      let res = await axios.get('/api/previousNotes/'+JSON.stringify(data))
      let prevnotesarr = []
      if(res.data.status!=null)
      res.data.status.value.forEach(noteobj=>{ 
        let obj = {date:noteobj.cr855_date.slice(0,10),note:noteobj.cr855_note}
        prevnotesarr.push(obj)
      })
      let mappednotes = prevnotesarr.map(note=> {return {date: moment(note.date), note:note.note}})
      mappednotes.sort((a,b) => a.date.diff(b.date))
      mappednotes = mappednotes.map(note=>{return {date: note.date.toISOString().slice(0,10), note:note.note}})
      let mappednotesarr = Object.values(mappednotes)
      setMappedNotesArr( mappednotesarr.reverse().slice(0,5))
    }
    if(chosenTeam!=null){
    getMembers()
    getPreviousNotes()
    }
  }, [chosenTeam])

  useDidMountEffect(() => {
    let notes = mappedNotesArr.map(note=>{
        return(<TextField
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            multiline
            value={note.date + '\n' + note.note.replace(/<br ?\/?>/g, "\n")}
            fullWidth={true}
            style={{marginTop:"10px",marginBottom:"10px"}}
        />)
    })
    setPrevNotes(notes)

  }, [mappedNotesArr])

  async function handleSubmission(){
    let today = new Date()
    today.setHours(0,0,0,0)
    let formattedNotes = notes.replace(/\n/g, "<br>");
   
    let adjustedToday = today.toDateString().slice(0,16)
    let graphToken = await acquireDataverseToken();
    let data = {
      cr855_date:adjustedToday,
      cr855_note: formattedNotes,
      cr855_group: chosenTeam,
      cr855_coach:coach.mail,
      accessToken: graphToken.accessToken,
      roles: graphToken.idTokenClaims.roles,
    }
    
    let res = await axios.post('/api/createNote/'+encodeURIComponent(JSON.stringify(data)))
    let noteId = res.data.status.cr855_studentnoteid
    let contacts = []
    

    await forEach(chosenTeamStudentsData, async(student)=>{
      if(student.isChecked){
        let data = {
          accessToken: graphToken.accessToken,
          roles: graphToken.idTokenClaims.roles,
          mail:student.mail
        }
      let res = await axios.get('/api/contact/'+ JSON.stringify(data))
      res.data.status.value.forEach(contact=>{
        contacts.push(contact.contactid)
      })}
    })

    await forEach(contacts,async(contact)=>{
      let data = {
        accessToken: graphToken.accessToken,
          roles: graphToken.idTokenClaims.roles,
        cr855_note_guid:noteId,
        cr855_contact_guid: contact
      }
      await axios.post('/api/linkContactToNote/'+JSON.stringify(data))
    })
    setRedirect('/success/'+chosenTeam)
  }

    let studentboxes = chosenTeamStudentsData.map((student,idx)=>{
      if(studentsLoading){
        return(
          <CircularProgress/>
        )
      }
      else{
      return(
        <Tooltip title= {student.mail}>
            <FormControlLabel
              value="start"
              control={     
              <Checkbox checked={chosenTeamStudentsData[idx].isChecked} onChange={(e)=>{
                let temp = [...chosenTeamStudentsData];
                temp[idx].isChecked = e.target.checked;
                setChosenTeamStudentsData(temp)}} />
                }
              label={student.name}
              labelPlacement="end"
            />
            </Tooltip>
        )
              }
    })
    const handleSnackbarClose = ()=>{
      setSnackbarOpen(false)
    }

    const handleCoachChange = (v)=>{
      setCoachAutocompleteValue(v);
      setNotes("");
      if(v!=null){
        setCoach(v);
        setCoachSelected(true)
      }
      else{
        setCoach({});
        setCoachSelected(false);}
          }

    if(redirect){
      return <Navigate to={redirect} state={'Notes2'}/>
    }
    else if(account){
      if(mobile){
          return (
              <div style={{
                  alignItems: 'center',
                  justifyContent: 'center',         
                }}>
                  <div style={{textAlign: "center"}}>
                  <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                      <Typography componet="h2" variant="h5" gutterBottom>
                          Coach
                      </Typography>
                    {coachesLoading?<CircularProgress/>:<div style={{display: 'flex', alignItems: 'center',
                  justifyContent: 'center'}}>
                      <Autocomplete
                      id="combo-box-demo"
                      options={coachesData}
                      getOptionLabel={option => option.label || ""}
                      value={coachAutocompleteValue}
                      style={{width:"60%"}}
                      onChange={(e, v) => {
                        handleCoachChange(v)
                        }}
                      renderInput={(params) => <TextField margin="dense" {...params} label="Coach" />}
                      />
                  </div>
                  }
                  </Box>
                  <Zoom in={coachSelected}>
                  <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                      <Typography componet="h2" variant="h5" gutterBottom>
                          Teams
                      </Typography>
                  {teamDataLoading?<CircularProgress/>:<div style={{display: 'flex', alignItems: 'center',
                  justifyContent: 'center'}}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={teamsData}
                      getOptionLabel={option => option.displayName || ""}
                      style={{width:"60%"}}
                      
                      inputValue={inputValue}
                      value={teamtextfieldvalue}
                      onInputChange={(_, v) => {setInputValue(v)}}
                      onChange={(e, v) => {setNotes("");setToggleTeam(!toggleTeam);setteamtextfieldvalue(v);setTeam(v.displayName);v!=null?setTeamSelected(true):setTeamSelected(false)}}
                      renderInput={(params) => <TextField margin="dense" {...params} label="Team" />}
                      />
                    </div>}
                  </Box>
                  </Zoom>                       
                  <Zoom in={teamSelected}>
                  <Box sx={{ bgcolor: 'background.paper',  boxShadow: 4,padding:1, marginBottom:5 }}>
                      <Typography componet="h2" variant="h5" gutterBottom>
                          {team} Attendance
                      </Typography>
                      {studentboxes}
                  </Box>
                  </Zoom>
                  <Zoom in={teamSelected}>
                  <Button style={{marginBottom:"10px"}} onClick={()=>setAttendanceComplete(true)} variant="contained">Complete attendance</Button>
                  </Zoom> 
                <Zoom in={attendanceComplete}>
                  <Box sx={{ bgcolor: 'background.paper',  padding:1,boxShadow: 4 , marginBottom:5 }}>
                  <AppBar style={{ background: '#F7941E' }} position="static">
                      <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                      >
                      <Tab label="Current" {...a11yProps(0)} />
                      <Tab label="Previous (all)" {...a11yProps(1)} />
                      </Tabs>
                  </AppBar>
                  <SwipeableViews 
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                  >
                      <TabPanel value={value} index={0} dir={theme.direction}>                
                          <TextField
                              label="Notes"
                              variant="outlined"
                              multiline
                              rows={18}
                              value={notes}
                              fullWidth={true}
                              onChange={(e)=>setNotes(e.target.value)}
                              style={{marginTop:"3px"}}
                          />                
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}> 
                          {prevNotes}  
                      </TabPanel>                
                  </SwipeableViews>
                  </Box>
                  </Zoom>
                  <Zoom in={teamSelected}>
                  <Button  sx={{ marginBottom: 5 }} disabled={notes===''?true:false} style={{backgroundColor:"white", width:'50%'}}onClick={()=>{handleSubmission();}} color='primary' variant="outlined" >Submit</Button>
                  </Zoom>
                  <Snackbar open={snackbarOpen}onClose={handleSnackbarClose} autoHideDuration={3000}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                      Successfully saved notes
                    </Alert>
                  </Snackbar>
                  
                  </div>
              </div>
        )   }
        
        else{
          return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',         
              }}>
                <div style={{textAlign: "center"}}>
                <Box  sx={{ bgcolor: 'background.paper', width: 720, boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                    <Typography componet="h2" variant="h5" gutterBottom>
                        Coach
                    </Typography>
                  {coachesLoading?<CircularProgress/>:<div style={{width:720,display: 'flex', alignItems: 'center',
                justifyContent: 'center'}}>
                    <Autocomplete
                    id="combo-box-demo"
                    options={coachesData}
                    getOptionLabel={option => option.label || ""}
                    value={coachAutocompleteValue}
                    style={{width:"60%"}}
                    onChange={(e, v) => {
                      handleCoachChange(v)
                      }}
                    renderInput={(params) => <TextField margin="dense" {...params} label="Coach" />}
                    />
                </div>
                }
                </Box>
                <Zoom in={coachSelected}>
                <Box  sx={{ bgcolor: 'background.paper', width: 720, boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                    <Typography componet="h2" variant="h5" gutterBottom>
                        Teams
                    </Typography>
                {teamDataLoading?<CircularProgress/>:<div style={{width:720,display: 'flex', alignItems: 'center',
                justifyContent: 'center'}}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={teamsData}
                    getOptionLabel={option => option.displayName || ""}
                    style={{width:"60%"}}
                    
                    inputValue={inputValue}
                    value={teamtextfieldvalue}
                    onInputChange={(_, v) => {setInputValue(v)}}
                    onChange={(e, v) => {setNotes("");setToggleTeam(!toggleTeam);setAttendanceComplete(false);setteamtextfieldvalue(v);setTeam(v.displayName);v!=null?setTeamSelected(true):setTeamSelected(false)}}
                    renderInput={(params) => <TextField margin="dense" {...params} label="Team" />}
                    />
                  </div>}
                </Box>
                </Zoom>                       
                <Zoom in={teamSelected}>
                <Box sx={{ bgcolor: 'background.paper', width: 720, boxShadow: 4,padding:1, marginBottom:5 }}>
                    <Typography componet="h2" variant="h5" gutterBottom>
                        {team} Attendance
                    </Typography>
                    {studentboxes}
                </Box>
                </Zoom>   
                <Zoom in={teamSelected}>
                  <Button style={{marginBottom:"10px"}} onClick={()=>setAttendanceComplete(true)} variant="contained">Complete attendance</Button>
                  </Zoom> 
                <Zoom in={attendanceComplete}>
                <Box sx={{ bgcolor: 'background.paper', width: 720, padding:1,boxShadow: 4 , marginBottom:5 }}>
                <AppBar style={{ background: '#F7941E' }} position="static">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab label="Current" {...a11yProps(0)} />
                    <Tab label="Previous (all)" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews 
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>                
                        <TextField
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={18}
                            value={notes}
                            fullWidth={true}
                            onChange={(e)=>setNotes(e.target.value)}
                            style={{marginTop:"3px"}}
                        />                
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}> 
                        {prevNotes}  
                    </TabPanel>                
                </SwipeableViews>
                </Box>
                </Zoom>
                <Zoom in={teamSelected}>
                <Button  sx={{ marginBottom: 5 }} disabled={notes===''?true:false} style={{backgroundColor:"white", width:'50%'}}onClick={()=>{handleSubmission();}} color='primary' variant="outlined" >Submit</Button>
                </Zoom>
                <Snackbar open={snackbarOpen}onClose={handleSnackbarClose} autoHideDuration={3000}>
                  <Alert severity="success" sx={{ width: '100%' }}>
                    Successfully saved notes
                  </Alert>
                </Snackbar>
                
                </div>
            </div>
       )   }
              }
        
      

        

};