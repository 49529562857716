import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import { PageLayout } from './components/PageLayout';
import { Notes } from './pages/Notes';
import {Notes2 } from './pages/Notes2';
import {PasswordManager} from './pages/PasswordManager'
import {ContactInfo} from './pages/ContactInfo'
import {Reports} from './pages/Reports'

import { Home } from './pages/Home';

import './styles/App.css';
import SuccessPage from './pages/SuccessPage';

const Pages = () => {
    return (
        <Routes>
            <Route path="/Notes" element={<Notes />} />
            <Route path="/Notes2" element={<Notes2 />} />
            <Route path="/Reports" element={<Reports />} />
            <Route path="/PasswordManager" element={<PasswordManager />} />
            <Route path="/ContactInfo" element={<ContactInfo />} />

            <Route path="/success/:chosenTeam" element={<SuccessPage />} />
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <PageLayout>
                <Pages />
            </PageLayout>
        </MsalProvider>
    );
};

export default App;