import React, { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import {Typography } from "@mui/material";
import { useParams } from'react-router-dom';
import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { protectedResources, msalConfig } from '../authConfig';
import { getClaimsFromStorage } from '../utils/storageUtils';
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import moment from "moment";
import axios from 'axios'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  

const SuccessPage = (props) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const [graphData, setGraphData] = useState(null);
  const resource = new URL(protectedResources.graphContacts.endpoint).hostname;
  
  const claims =
      account && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
          ? window.atob(
              getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
          )
          : undefined; // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
  
  const request = {
      scopes: protectedResources.graphContacts.scopes,
      account: account,
      claims: claims
  };
  const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
      ...request,
      redirectUri: '/redirect.html',
  });

  useEffect(() => {
      //on login
      if (!!graphData) {
          return;
      }

      if (!!error) {
          if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
              login(InteractionType.Redirect, request);
          }

          console.log(error);
          return;
      }
  }, [graphData, result, error, login]);

  if (error) {
      return <div>Error: {error.message}</div>;
  }

  async function acquireDataverseToken(){
    return await instance.acquireTokenSilent({
        scopes: ['https://coder2.crm3.dynamics.com/.default'],
        account: account
          })
  }
  
    const theme = useTheme();
    const [dialogOpen, setDialogOpen]=useState(true)
    const [prevNotes, setPrevNotes] = useState([])
    const [mappedNotesArr, setMappedNotesArr]=useState([])
    const {state} = useLocation()
    const {chosenTeam} = useParams()
   
    const useDidMountEffect = (func, deps) => {
      const didMount = useRef(false);
      useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
      }, deps);
    }
  
    useEffect(() => { 
      async function getPreviousNotes(){
        let dataverseToken = await acquireDataverseToken();
        let data = {
          chosenTeam: chosenTeam,
          accessToken: dataverseToken.accessToken,
          roles: dataverseToken.idTokenClaims.roles
        }
        let res = await axios.get('/api/previousNotes/'+JSON.stringify(data))
      let prevnotesarr = []
      if(res.data.status!=null)
      res.data.status.value.forEach(noteobj=>{ 
        let obj = {date:noteobj.cr855_date.slice(0,10),note:noteobj.cr855_note}
        prevnotesarr.push(obj)
      })
      let mappednotes = prevnotesarr.map(note=> {return {date: moment(note.date), note:note.note}})
      mappednotes.sort((a,b) => a.date.diff(b.date))
      mappednotes = mappednotes.map(note=>{return {date: note.date.toISOString().slice(0,10), note:note.note}})
      let mappednotesarr = Object.values(mappednotes)
      setMappedNotesArr( mappednotesarr.reverse().slice(0,5))      
    }
    getPreviousNotes()
    }, [])
    
    useDidMountEffect(() => {
      let notes = mappedNotesArr.map(note=>{
          return(<TextField
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              multiline
              value={note.date + '\n' + note.note.replace(/<br ?\/?>/g, "\n")}
              fullWidth={true}
              style={{marginTop:"10px",marginBottom:"10px"}}
          />)
      })
      setPrevNotes(notes)
  
    }, [mappedNotesArr])
  
      const handleDialogClose = () => {
        setDialogOpen(false);
      }
    return (
        <div>
         <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Successfully saved notes</DialogTitle>
        <DialogActions>
              <Button variant='outlined' onClick={handleDialogClose}>Continue</Button>
        </DialogActions>

        </Dialog>
            <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign:'center'         
          }}>
            <Box  sx={{ bgcolor: 'background.paper', width: 400, boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
            <AppBar style={{ background: '#F7941E' }} position="static">
            <Typography componet="h2" variant="h5">
                    Previous Notes
                </Typography>
            </AppBar>   
            <TabPanel value={0} index={0} dir={theme.direction}>
                    {prevNotes}
                </TabPanel>
            </Box>
            </div>
             <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',         
              }}>
              <Button style={{backgroundColor:"white"}} variant='outlined' href={'/'+state}>Return home</Button>
              </div>
              </div>
   )
}

export default SuccessPage;