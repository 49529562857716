import { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Nav, Navbar, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { loginRequest } from '../authConfig';
import { AccountPicker } from './AccountPicker';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import InsightsIcon from '@mui/icons-material/Insights';
import EventNoteIcon from '@mui/icons-material/EventNote';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { clearStorage } from '../utils/storageUtils';
import React, { useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import LockResetIcon from '@mui/icons-material/LockReset';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
export const NavigationBar = () => {
    const [showProfilePicker, setShowProfilePicker] = useState(false);
    const { instance } = useMsal();


    const [mobile, setMobile] = useState(false);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

      let iconmap = new Map()
      iconmap.set(0,<EventNoteIcon/>)
      iconmap.set(1,<EventNoteIcon/>)
      iconmap.set(2,<InsightsIcon/>)
      iconmap.set(3,<LockResetIcon/>)
      iconmap.set(4,<PhoneIcon/>)


      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
            {['Notes', 'Notes2', 'Reports', 'PasswordManager','ContactInfo'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <Link to = {'/'+text }>
                <ListItemButton>
                  <ListItemIcon>
                    {iconmap.get(index)}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
                </Link>
              </ListItem>
            ))}
          </List>
         
        </Box>
      );
    

    useEffect(() => {
        const setResponsiveness = () => {
          return window.innerWidth < 900
            ? setMobile(true)
            : setMobile(false);
        };
    
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    
        return () => {
          window.removeEventListener("resize", () => setResponsiveness());
        }
      }, []);
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
         */

        instance.loginRedirect(loginRequest)
            .catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        let account = instance.getActiveAccount();
        clearStorage(account);

        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    };

    const handleSwitchAccount = () => {
        setShowProfilePicker(!showProfilePicker);
    };

    const handleLoginPopup = () => {
        instance.loginPopup({
            ...loginRequest,
            redirectUri: '/redirect.html'
        }).catch((error) => console.log(error));
    };

    const handleLogoutPopup = () => {
        let account = instance.getActiveAccount();
        clearStorage(account);

        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: instance.getActiveAccount(),
        });
    };

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    if(mobile){
        return (
            
            <>

            

                <Navbar bg="primary" variant="dark" className="navbarStyle" >
                <IconButton onClick={toggleDrawer('top', true)}
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                    }}
                    >
                    <MenuIcon />
                </IconButton>
                <Drawer
                        anchor={'top'}
                        open={state['top']}
                        onClose={toggleDrawer('top', false)}
                    >
                        {list('top')}
                    </Drawer>
                <a className="navbar-brand" href="/">
                        Coder Sports Academy
                    </a>
                    <AuthenticatedTemplate>
                    <div className="collapse navbar-collapse justify-content-end">
                            <DropdownButton
                                variant="warning"
                                
                                title={activeAccount ? activeAccount.name : 'Unknown'}
                            >
                                <Dropdown.Item as="button" onClick={handleLogoutPopup}>
                                    Sign out
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                       
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <div className="collapse navbar-collapse justify-content-end">
                            <Button className="ml-auto" drop="start" title="Sign In" onClick={handleLoginPopup}>
                                    Sign in
                            </Button>
                        </div>
                    </UnauthenticatedTemplate>
                </Navbar>
                <AccountPicker show={showProfilePicker} handleSwitchAccount={handleSwitchAccount} />
            </>
        );
    }

    else{
        return (
            <>
                <Navbar bg="primary" variant="dark" className="navbarStyle">
                    <a className="navbar-brand" href="/">
                        Coder Sports Academy
                    </a>
                    <AuthenticatedTemplate>
                    
                        {(activeAccount!=null && activeAccount.idTokenClaims.roles!=null && activeAccount.idTokenClaims.roles.includes("Coach"))?(
                            <>
                        <Nav.Link className="navbarButton" href="/Notes">
                            Notes
                        </Nav.Link>
                        <Nav.Link className="navbarButton" href="/Notes2">
                            Notes2
                        </Nav.Link>
                        <Nav.Link className="navbarButton" href="/Reports">
                            Reports
                        </Nav.Link>
                        <Nav.Link className="navbarButton" href="/PasswordManager">
                            Password Manager
                        </Nav.Link>
                        <Nav.Link className="navbarButton" href="/ContactInfo">
                            Contact Information
                        </Nav.Link>
                        <a style={{textDecoration:"none", color:'white'}} href='https://learn.codersports.com/coach-resources'target="_blank" >
                            Coach Resources
                        </a>
                        
                        </>):null }
                        <div className="collapse navbar-collapse justify-content-end">
                            <DropdownButton
                                variant="warning"
                                
                                title={activeAccount ? activeAccount.name : 'Unknown'}
                            >
                                <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                                    Sign out
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                        
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <div className="collapse navbar-collapse justify-content-end">
                            <Button className="ml-auto" drop="start" title="Sign In" onClick={handleLoginRedirect}>
                                    Sign in
                            </Button>
                        </div>
                    </UnauthenticatedTemplate>
                </Navbar>
                <AccountPicker show={showProfilePicker} handleSwitchAccount={handleSwitchAccount} />
            </>
        );
    }
   
};
