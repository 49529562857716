import { AuthenticatedTemplate } from '@azure/msal-react';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import  Avatar  from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Box from '@mui/material/Box';
import  Card  from '@mui/material/Card';
import { Link } from 'react-router-dom';
import { NavigationBar } from './NavigationBar';
import CardActionArea from "@mui/material/CardActionArea";
import InsightsIcon from '@mui/icons-material/Insights';
import CardContent from "@mui/material/CardContent";
export const PageLayout = (props) => {
  
  
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        
        <>
            <NavigationBar />
            <UnauthenticatedTemplate>
            <br />
            <h5>
                <center>Please login to your Coder Sports Academy account</center>
            </h5>
            </UnauthenticatedTemplate>
            
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate>
            
            

            </AuthenticatedTemplate>
        </>
    );
};
