import React, { useEffect, useState, useRef, Fragment } from "react";
import axios from 'axios'
import { useMsalAuthentication, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { protectedResources, msalConfig } from '../authConfig';
import { getClaimsFromStorage } from '../utils/storageUtils';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Typography } from "@mui/material";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";
import Zoom from '@mui/material/Zoom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '11',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '11',
  },
}));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  export const ContactInfo = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [graphData, setGraphData] = useState(null);
    const resource = new URL(protectedResources.graphContacts.endpoint).hostname;
    
    const claims =
        account && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            ? window.atob(
                getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${resource}`)
            )
            : undefined; // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
    
    const request = {
        scopes: protectedResources.graphContacts.scopes,
        account: account,
        claims: claims
    };

    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        redirectUri: '/redirect.html',
    });

    useEffect(() => {
        //on login
        if (!!graphData) {
            return;
        }

        if (!!error) {
            if (error.errorCode === 'popup_window_error' || error.errorCode === 'empty_window_error') {
                login(InteractionType.Redirect, request);
            }

            console.log(error);
            return;
        }
    }, [graphData, result, error, login]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    async function acquireDataverseToken(){
      return await instance.acquireTokenSilent({
          scopes: ['https://coder2.crm3.dynamics.com/.default'],
          account: account
            })
    }
    async function acquireGraphToken(){
      return await
        instance.acquireTokenSilent({
            scopes: ["user.read","Group.Read.All","GroupMember.Read.All","Team.ReadBasic.All","TeamMember.Read.All"],
            account: account
        })
    
    }

    const [student, setStudent] = useState({});
    const [studentsData, setStudentsData] = useState([{label:" "}])
    const [studentAutocompleteValue, setStudentAutocompleteValue] = useState({label:""})
    const [toggleStudent, setToggleStudent]= useState(false)
    const [accountParentInfo, setAccountParentInfo]=useState({})
    const [studentSelected, setStudentSelected]=useState(false)
    const [studentsLoading, setStudentsLoading]=useState(true)
    const [mobile, setMobile] = useState(false);

  useEffect(() => {
    async function token(){
        
      let students = []
      let teams = []
      let dataverseToken = await acquireDataverseToken();
      let graphToken = await acquireGraphToken();

      let data = {
        accessToken: dataverseToken.accessToken,
        roles: dataverseToken.idTokenClaims.roles,
      }
      axios.get("/api/students/" + JSON.stringify(data)).then(response => {
        response.data.status.value.forEach((studentData) => {
            studentData.label = studentData.fullname
            students.push(studentData)
        })

        students.sort((a,b)=>a.fullname.localeCompare(b.fullname))
        setStudentsData(students)
        setStudentsLoading(false)
    })
    }
    token()
  }, [])

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobile(true)
        : setMobile(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  useEffect(() => {
    async function token(){
      console.log(student)
      let dataverseToken = await acquireDataverseToken();
      console.log(student)
      console.log("test")
      let data = {
        accessToken: dataverseToken.accessToken,
        roles: dataverseToken.idTokenClaims.roles,
        accountName: student._fw_parentaccount_value
      }
      let res = await axios.get('/api/account/'+ JSON.stringify(data))
      console.log(res.data.status.value[0]._primarycontactid_value)
      let contactdata = {
        accessToken: dataverseToken.accessToken,
        roles: dataverseToken.idTokenClaims.roles,
        id:res.data.status.value[0]._primarycontactid_value
      }
      let contactres = await axios.get('/api/contactById/'+ JSON.stringify(contactdata))
      console.log("here")
      console.log(contactres)
      let contact = contactres.data.status.value[0]
      let telephone = contact.address1_telephone1!=null?contact.address1_telephone1:contact.address2_telephone1!=null?contact.address2_telephone1:contact.address3_telephone1!=null?contact.address3_telephone1:contact.mobilephone!=null?contact.mobilephone:contact.telephone1!=null?contact.telephone1:contact.telephone2!=null?contact.telephone2:contact.telephone3!=null?contact.telephone3:"Not Found"
      console.log(contactres)
      setAccountParentInfo({name:contact.fullname,
                            telephone:telephone,
                            email:contact.emailaddress1})
    }
    if(studentSelected){
      token()
    }
  }, [studentSelected,toggleStudent])
  const handleStudentChange = (v)=>{
    setToggleStudent(!toggleStudent)
    setStudentAutocompleteValue(v);
    if(v!=null){
      setStudent(v);
      setStudentSelected(true)
    }
    else{
      setStudent({});
      setStudentSelected(false);}
  }

  const defaultProps = {
    options: studentsData,
    getOptionLabel: option => option.label
  }
  if(mobile){
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',         
          }}>
            <div style={{textAlign: "center"}}>
            <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
                <Typography componet="h2" variant="h5" gutterBottom>
                    Student
                </Typography>
              {studentsLoading?<CircularProgress/>:<div style={{display: 'flex', alignItems: 'center',
            justifyContent: 'center'}}>
                <Autocomplete
                {...defaultProps}
                id="combo-box-demo"
                renderOption= {(props,option) => {
                  return(
                  <div>
                    <Tooltip placement="left" title={option.cr855_codersportsemail}>
                    <Button sx={{ textTransform: 'none' }}  component="li" {...props} fullWidth>{props.key}</Button> 
                    </Tooltip>
                    </div> 
                  )
                 }
                }
                value={studentAutocompleteValue}
                style={{width:"100%"}}
                onChange={(e, v) => {
                  handleStudentChange(v)
                  }}
                renderInput={(params) => <TextField margin="dense" {...params} label="Student" />}
                />
            </div>
            }
            </Box>
            <Zoom in = {studentSelected}>
            <div style={{ display:'inline-block',justifyContent:'center'}}>
                <Box  sx={{bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
                <TableContainer component={Paper}>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Parent Name</StyledTableCell>
                    </TableRow>                   
                  </TableHead>
                  <TableBody>                 
                  <TableRow>                     
                      <StyledTableCell align="left">{accountParentInfo.name}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Email</StyledTableCell>
                    </TableRow>                   
                  </TableHead>
                  <TableBody>                 
                  <TableRow>                     
                      <StyledTableCell align="left">{accountParentInfo.email}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Phone</StyledTableCell>
                    </TableRow>                   
                  </TableHead>
                  <TableBody>                 
                  <TableRow>                     
                      <StyledTableCell align="left"><a href={"tel:" + accountParentInfo.telephone}>{accountParentInfo.telephone}</a></StyledTableCell>                                   
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </Box>              
              </div>    
            </Zoom>
            <Zoom in = {studentSelected}>
            <div style={{display:'inlineBlock',justifyContent:'center'}}>
                <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4 }}>
                <TableContainer component={Paper}>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Scratch Username</StyledTableCell>
                    </TableRow>              
                  </TableHead>
                  <TableBody>          
                  <TableRow>         
                      <StyledTableCell align="left">{student.cr855_scratchaccount?student.cr855_scratchaccount:"N/A"}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Scratch Password</StyledTableCell>
                    </TableRow>              
                  </TableHead>
                  <TableBody>          
                  <TableRow>         
                      <StyledTableCell align="left">{student.cr855_scratchpswd?student.cr855_scratchpswd:"N/A"}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Replit Username</StyledTableCell>
                    </TableRow>              
                  </TableHead>
                  <TableBody>          
                  <TableRow>         
                      <StyledTableCell align="left">{student.cr855_replit?student.cr855_replit:"N/A"}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <StyledTableCell>Replit Password</StyledTableCell>
                    </TableRow>              
                  </TableHead>
                  <TableBody>          
                  <TableRow>         
                      <StyledTableCell align="left">{student.cr855_replitpswd?student.cr855_replitpswd:"N/A"}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </Box>        
              </div>     
              </Zoom>
            </div>
        </div>
   )   }
   else{
    return (
      <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',         
        }}>
          <div style={{textAlign: "center"}}>
          <Box  sx={{ bgcolor: 'background.paper', width: 720, boxShadow: 4,padding:1,marginTop:8,marginBottom:5 }}>
              <Typography componet="h2" variant="h5" gutterBottom>
                  Student
              </Typography>
            {studentsLoading?<CircularProgress/>:<div style={{width:720,display: 'flex', alignItems: 'center',
          justifyContent: 'center'}}>
              <Autocomplete
              {...defaultProps}
              id="combo-box-demo"
              renderOption= {(props,option) => {
                return(
                <div>
                  <Tooltip placement="left" title={option.cr855_codersportsemail}>
                  <Button sx={{ textTransform: 'none' }}  component="li" {...props} fullWidth>{props.key}</Button> 
                  </Tooltip>
                  </div> 
                )
               }
              }
              value={studentAutocompleteValue}
              style={{width:"60%"}}
              onChange={(e, v) => {
                handleStudentChange(v)
                }}
              renderInput={(params) => <TextField margin="dense" {...params} label="Student" />}
              />
          </div>
          }
          </Box>
          <Zoom in = {studentSelected}>
          <div style={{display:'flex',justifyContent:'center'}}>
              <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
              <TableContainer component={Paper}>
              <Table aria-label="customized table">
              <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <StyledTableCell>Parent Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Phone</StyledTableCell>
                  </TableRow>                   
                </TableHead>
                <TableBody>                 
                <TableRow>                     
                    <StyledTableCell align="left">{accountParentInfo.name}</StyledTableCell>
                    <StyledTableCell align="left">{accountParentInfo.email}</StyledTableCell>
                    <StyledTableCell align="left"><a href={"tel:" + accountParentInfo.telephone}>{accountParentInfo.telephone}</a></StyledTableCell>                                   
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </Box>              
            </div>    
          </Zoom>
          <Zoom in = {studentSelected}>
          <div style={{display:'flex',justifyContent:'center'}}>
              <Box  sx={{ bgcolor: 'background.paper', boxShadow: 4,padding:1,marginTop:3,marginBottom:3 }}>
              <TableContainer component={Paper}>
              <Table aria-label="customized table">
              <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <StyledTableCell>Scratch Username</StyledTableCell>
                    <StyledTableCell>Scratch Password</StyledTableCell>
                    <StyledTableCell>Replit Username</StyledTableCell>
                    <StyledTableCell>Replit Password</StyledTableCell>
                  </TableRow>              
                </TableHead>
                <TableBody>          
                <TableRow>         
                    <StyledTableCell align="left">{student.cr855_scratchaccount?student.cr855_scratchaccount:"N/A"}</StyledTableCell>
                    <StyledTableCell align="left">{student.cr855_scratchpswd?student.cr855_scratchpswd:"N/A"}</StyledTableCell>
                    <StyledTableCell align="left">{student.cr855_replit?student.cr855_replit:"N/A"}</StyledTableCell>
                    <StyledTableCell align="left">{student.cr855_replitpswd?student.cr855_replitpswd:"N/A"}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </Box>        
            </div>     
            </Zoom>
          </div>
      </div>
 ) 
   
   }
  }


